<template>
  <ui-component-modal
    modalTitle="Remove location from channel"
    buttonLabelConfirm="Delete"
    confirmButtonCssClass="is-danger"
    :isSaving="isDeleting"
    :isSavingSuccess="isDeletingSuccess"
    :isSavingError="isDeletingError"
    :hideFooterButtons="isDeletingError || isDeletingSuccess"
    :disableSaveButton="!isDeleteConfirmed"
    :onClickCancel="onClickCancel"
    :onClickSave="deleteLocationChannel"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div>
        <div class="has-margin-bottom is-size-5 has-text-centered">
          Are you sure you want to remove this channel from the location?
        </div>
        <div class="has-text-centered">
          <BaseCheckbox
            v-model="isDeleteConfirmed"
            class="has-text-left"
            :label="'Yes, I am sure!'"
          />
        </div>
        <Message
          message="The actual location won't be deleted, only it's presence on this channel."
          :type="'is-info'"
          class="has-margin-bottom"
        />
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import locationProvider from '@/providers/location'

const BaseCheckbox = () => import('@/components/UI/Form/BaseCheckbox')
const Message = () => import('@/components/UI/Message')

export default {
  components: {
    BaseCheckbox,
    Message,
  },

  props: {
    location: {
      type: Object,
      default: null,
    },

    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isDeleteConfirmed: false,
      isDeleting: false,
      isDeletingSuccess: false,
      isDeletingError: false,
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
    ...mapState('locationStore', ['locationsPageState']),
  },

  created() {},

  methods: {
    ...mapMutations('locationStore', ['setLocationsPageState']),

    deleteLocationChannel() {
      let self = this

      if (!self.isDeleting) {
        self.isDeleting = true

        locationProvider.methods
          .deleteLocationChannel(self.location.Id, self.channel.Id)
          .then((response) => {
            if (response.status === 204) {
              self.isDeletingSuccess = true

              let locationIndex = self.locationsPageState.locations.findIndex(
                (l) => l.Id === self.location.Id
              )
              if (locationIndex > -1) {
                let locationsPageState = JSON.parse(
                  JSON.stringify(self.locationsPageState)
                )
                locationsPageState.locations.splice(locationIndex, 1)
                self.setLocationsPageState(locationsPageState)
              }

              let t = setTimeout(() => {
                self.onClickCancel()
                clearTimeout(t)
              }, 1500)
            }
          })
          .catch((error) => {
            self.isDeletingError = true
          })
          .finally(() => {
            self.isDeleting = true
          })
      }
    },
  },
}
</script>
